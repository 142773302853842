/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { getI18n, Trans, useTranslation } from "react-i18next";
import Image from "../../../../components/Image";
import appstoreJA from "./appstore_ja.svg";
import appstoreEN from "./appstore_en.svg";
import playmarketJA from "./playmarket_ja.png";
import playmarketEN from "./playmarket_en.png";
import { StaticImage } from "gatsby-plugin-image";

const APPSTORE = new Map([
  ["en", appstoreEN],
  ["ja", appstoreJA],
]);

const PLAYMARKET = new Map([
  ["en", playmarketEN],
  ["ja", playmarketJA],
]);

const PLAYMARKET2 = new Map([
  [
    "en",
    <StaticImage
      src={"./playmarket_en.png"}
      height={77}
      style={{ margin: "-12px" }}
      alt="play market url"
    />,
  ],
]);

const SidebarInfo = ({
  client,
  releaseDate,
  appStoreUrl,
  playStoreUrl,
}: {
  client: string;
  releaseDate: string;
  appStoreUrl: string;
  playStoreUrl: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const i18n = getI18n();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography
        variant="h6"
        data-aos={"fade-up"}
        sx={{
          fontWeight: 600,
        }}
      >
        {client}
      </Typography>
      <Typography
        variant="body1"
        data-aos={"fade-up"}
        sx={{
          fontWeight: 400,
          marginBottom: 2,
          color: "#646E73",
        }}
      >
        <Trans>client</Trans>
      </Typography>
      <Typography
        variant="h6"
        data-aos={"fade-up"}
        sx={{
          fontWeight: 600,
        }}
      >
        {releaseDate}
      </Typography>
      <Typography
        variant="body1"
        data-aos={"fade-up"}
        sx={{
          fontWeight: 400,
          marginBottom: 2,
          color: "#646E73",
        }}
      >
        <Trans>release date</Trans>
      </Typography>
      {appStoreUrl && (
        <Link
          sx={{ display: "block", mt: "0.7rem" }}
          target="_blank"
          href={appStoreUrl}
        >
          <Image
            src={APPSTORE.get(i18n.language)}
            height={56}
            alt="app store url"
          />
        </Link>
      )}
      {playStoreUrl && (
        <Link
          sx={{ display: "block", mt: ".9rem" }}
          target="_blank"
          href={playStoreUrl}
        >
          <Image
            src={PLAYMARKET.get(i18n.language)}
            height={77}
            sx={{ margin: "-12px" }}
            alt="play market url"
          />
        </Link>
      )}
      <Link
        sx={{ display: "block", mt: ".9rem" }}
        target="_blank"
        href={playStoreUrl}
      >
        <StaticImage
          src={"./playmarket_en.png"}
          height={77}
          style={{ margin: "-12px" }}
          alt="play market url"
        />
      </Link>
      <Link
        sx={{ display: "block", mt: ".9rem" }}
        target="_blank"
        href={playStoreUrl}
      >
        {PLAYMARKET2.get("en")}
      </Link>
    </Box>
  );
};

export default SidebarInfo;

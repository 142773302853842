import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Main from "../layouts/Main";
import Container from "../components/Container";
import { Hero } from "../views/BlogArticle/components";
import { graphql } from "gatsby";
import { Divider, IconButton, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTranslation } from "react-i18next";

//TODO replace ANY with props generated from usage
export default ({ data: { markdownRemark } }: any): JSX.Element => {
  const { t } = useTranslation();
  const { frontmatter, html } = markdownRemark;

  const [location, setLocation] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      // @ts-ignore
      setLocation(window.location);
    }
  }, []);

  return (
    <Main colorInvert={true}>
      <Box>
        <Hero
          title={frontmatter.title}
          description={frontmatter.description}
          date={frontmatter.date}
        />
        <Container>
          <Grid container spacing={4}>
            <Grid item>
              <Box sx={{ mt: { xs: 0, md: "-1.5rem" } }}>
                <Box
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </Box>
            </Grid>
            {/*<Grid item xs={12} md={4}>*/}
            {/*  <Box marginBottom={4}>*/}
            {/*    <SidebarInfo*/}
            {/*      client={frontmatter.client}*/}
            {/*      releaseDate={frontmatter.releaseDate}*/}
            {/*      appStoreUrl={frontmatter.appStoreUrl}*/}
            {/*      playStoreUrl={frontmatter.playStoreUrl}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
          </Grid>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Box>
                <Typography color={"text.secondary"}>
                  {frontmatter.date}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <Typography color={"text.secondary"}>{t("Share:")}</Typography>
              <Box marginLeft={0.5}>
                <IconButton
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${location}`}
                  target="_blank"
                  aria-label="linkedin"
                  sx={{ color: "#4e4e4e" }}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  // TODO add i18n for post text
                  href={`https://www.facebook.com/sharer/sharer.php?u=${location}&hashtag=%bogunov_inc&quote=Checkout%20BOGUNOV%20Inc%20post!`}
                  target="_blank"
                  aria-label="facebook"
                  sx={{ color: "#4e4e4e" }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  href={`https://twitter.com/intent/tweet?text=Checkout%20BOGUNOV%20Inc%20post!%20${location}&hashtags=bogunov_inc`}
                  target="_blank"
                  aria-label="twitter"
                  sx={{ color: "#4e4e4e" }}
                >
                  <TwitterIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        tags
        icon {
          publicURL
        }
        background {
          publicURL
        }
        client
        releaseDate
        appStoreUrl
        playStoreUrl
        slug
        date(formatString: "YYYY/MM/DD")
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";

import Container from "../../../../components/Container";
import Tags from "../../../../components/Tags";

const Hero = ({
  title,
  description,
  date,
}: {
  title: string;
  description: string;
  date: string;
}): JSX.Element => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   const jarallaxInit = async () => {
  //     const jarallaxElems = document.querySelectorAll(".jarallax");
  //     if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
  //       return;
  //     }
  //
  //     const { jarallax } = await import("jarallax");
  //     jarallax(jarallaxElems, { speed: 0.2 });
  //   };
  //
  //   jarallaxInit();
  // });

  return (
    <Box
      // className={"jarallax"}
      // data-jarallax
      // data-speed="0.2"
      position={"relative"}
      minHeight={{ xs: 150, sm: 300, md: 300 }}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
      display={"flex"}
      marginTop={-13}
      paddingTop={13}
      alignItems={"center"}
      id="agency__portfolio-item--js-scroll"
    >
      <Box
        // className={"jarallax-img"}
        sx={{
          position: "absolute",
          objectFit: "cover",
          /* support for plugin https://github.com/bfred-it/object-fit-images */
          fontFamily: "object-fit: cover;",
          bottom: 0,
          // left: 0,
          width: "100%",
          maxWidth: "2700px",
          height: "75%",
          zIndex: 3,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right bottom",
          // backgroundImage: `url(${background})`,
          opacity: { xs: 0.2, sm: 1 },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha("#323233", 1),
          zIndex: 2,
        }}
      />
      <Container position={"relative"} zIndex={4}>
        <Box>
          {/*<Box display={{ xs: "flex", xl: "none" }} alignItems={"center"}>*/}
          {/*<Avatar*/}
          {/*  sx={{*/}
          {/*    width: 72,*/}
          {/*    height: 72,*/}
          {/*    mt: "1rem",*/}
          {/*    mb: "1rem",*/}
          {/*  }}*/}
          {/*  src={icon}*/}
          {/*/>*/}
          {/*</Box>*/}
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              color: "common.white",
              marginBottom: 1,
            }}
          >
            <Box
              display="flex"
              alignItems={"center"}
              mt={{ xs: "1rem", xl: 0 }}
              dangerouslySetInnerHTML={{ __html: title }}
            >
            </Box>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              color: "common.white",
              marginTop: { xs: 3, sm: 0 },
              marginBottom: { xs: 4, sm: 2 },
            }}
          >
            {date}
          </Typography>
          {/*<Tags tags={tagsList} />*/}
          {/*<ListItemText*/}
          {/*  sx={{ margin: 0 }}*/}
          {/*  primary={"Jhon Anderson"}*/}
          {/*  secondary={"May 19, 2021"}*/}
          {/*  primaryTypographyProps={{*/}
          {/*    variant: "h6",*/}
          {/*    sx: { color: "common.white" },*/}
          {/*  }}*/}
          {/*  secondaryTypographyProps={{*/}
          {/*    sx: { color: alpha("#ffffff", 0.8) },*/}
          {/*  }}*/}
          {/*/>*/}
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
